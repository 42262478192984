/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */

import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import DOMPurify from 'dompurify';
import Main from '../../components/layout/Main';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import useLanguage from '../../core/language/useLanguage';

import { getConsultationsRequest, selectSingleConsultationRequest } from '../../core/consultation/consultationActions';
import history from '../../core/utils/history';

import './consultations.scss';

const Consultations = () => {
    const { language, getLanguagesRequest } = useLanguage();
    const consultations = useSelector(state => state.consultation);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [elRefs, setElRefs] = useState([]);

    useEffect(() => {
        getLanguagesRequest();
        dispatch(getConsultationsRequest());
        window.scrollTo(0, 0);
    }, []);

    const groupByCategory = (items) => {
        let grouped = items.reduce((r, a) => {
            const categoryTranslation = getSelectedTranslation(a?.category?.translations, language?.selectedLanguage?.id);
            if (!r[a.category.id]) {
                r[a.category.id] = {};
            }
            r[a.category.id].data = r[a.category.id].data || [];
            r[a.category.id].data.push(a);
            r[a.category.id].title = categoryTranslation?.name;

            return r;
        }, Object.create(null));
        grouped = Object.values(grouped);
        return grouped;
    };

    const goToConsultationDetail = (data) => {
        dispatch(selectSingleConsultationRequest(data));
        history.push(`consultation-detail/${data?.slug}`);
    };

    const scrollTo = (dataScrollTo) => {
        elRefs[dataScrollTo].current.scrollIntoView({ behavior: 'smooth' });
    };

    const groupedConsultations = groupByCategory(consultations.allConsultations);
    const numGroups = groupedConsultations.length;

    useEffect(() => {
        // add or remove refs
        setElRefs((currentRef) => Array(numGroups)
            .fill()
            .map((_, i) => currentRef[i] || createRef()));
    }, [numGroups]);

    const Item = ({ item }) => {
        const itemTranslation = getSelectedTranslation(item?.translations, language?.selectedLanguage?.id);
        const sanitizer = DOMPurify.sanitize;

        return (
            <div className="c-item">
                <Row className="c-item-row">
                    <Col span={24}>
                        <h4 className="c-item-title">{itemTranslation?.title}</h4>
                        <h5 className="c-item-subtitle">{itemTranslation?.subtitle}</h5>
                    </Col>
                </Row>
                <Row className="c-item-row">
                    <Col span={24}>
                        <p className="c-item-description" dangerouslySetInnerHTML={{ __html: sanitizer(itemTranslation?.short_description) }} />
                    </Col>
                </Row>
                <Row className="c-item-row" align="middle">
                    <Col span={12}>
                        <span className="c-item-cost">£{item.amount.toFixed(2)}</span>
                    </Col>
                    <Col span={12}>
                        <button type="button"
                            className="c-item-button"
                            onClick={() => (user?.userData?.id !== 'undefined' && user?.userData?.id !== null && user?.userData?.id !== ''
                            && user?.userData.id !== undefined
                                ? goToConsultationDetail(item)
                                : history.push('/?redirect=consultations'))}>
                            {getTranslation(language, 'consultation_button_book', 'More Detail')}
                        </button>
                    </Col>
                </Row>
            </div>
        );
    };

    const Section = ({ section, index }) => (
        <div key={section.id} ref={elRefs[index]} className="c-section">
            <h3 className="c-section-title">{section?.title}</h3>
            {section.data.map(groupedConsultation => (
                <Item item={groupedConsultation} />
            ))}
        </div>
    );

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            displayRightMenuConsultations
            menu={groupedConsultations}
            scrollTo={scrollTo}
            showFooter={false}>
            <div className="consultations">
                <h2 className="c-page-heading">{getTranslation(language, 'settings_screen_consultations_link_title', 'Consultations')}</h2>
                {
                    consultations?.isFetching
                        ? (
                            <div className="loaderSpacer">
                                <Spin size="large" />
                            </div>
                        )
                        : groupedConsultations.map((g, index) => <Section section={g} index={index} key={g.id} />)
                }
            </div>
        </Main>
    );
};

export default Consultations;

/* eslint-disable react/no-danger */

import DOMPurify from 'dompurify';
import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isUndefined, size } from 'underscore';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Main from '../../components/layout/Main';
import useLanguage from '../../core/language/useLanguage';
import { getSelectedTranslation, getHoroscopeDataRange } from '../../core/utils/helpers';
// import history from '../../core/utils/history';
// import { saveOneOffPaymentRequest } from '../../core/payment/paymentActions';
import './singleHoroscope.scss';
import OneOffPurchaseButton from '../../components/OneOffPurchaseButton';
import HoroscopeCarousel from '../../components/HoroscopeCarousel';

const SingleHoroscope = () => {
    const horoscope = useSelector(state => state.horoscope);

    const { singleHoroscope } = horoscope;
    const { language } = useLanguage();
    const partsLength = size(singleHoroscope?.horoscopeData?.parts);
    const [elRefs, setElRefs] = useState([]);
    const sanitizer = DOMPurify.sanitize;

    const { previousHoroscopes } = singleHoroscope;
    const allHoroscopes = [singleHoroscope, ...previousHoroscopes];
    const reversedHoroscopes = allHoroscopes.reverse();

    const getInitialActiveHoroscopeIndex = () => {
        const currentDate = moment();
        const futureHoroscopes = [];
        const pastHoroscopes = [];
        let currentHoroscope;

        reversedHoroscopes.forEach(horoscopeObject => {
            if (moment(horoscopeObject.current_period.from).isAfter(currentDate)) {
                futureHoroscopes.push(horoscopeObject);
            } else if (moment(horoscopeObject.current_period.to).isBefore(currentDate)) {
                pastHoroscopes.push(horoscopeObject);
            } else {
                currentHoroscope = horoscopeObject;
            }
        });

        if (currentHoroscope) {
            return reversedHoroscopes.indexOf(currentHoroscope);
        }
        if (futureHoroscopes.length > 0) {
            return reversedHoroscopes.indexOf(futureHoroscopes[0]);
        }
        if (pastHoroscopes.length > 0) {
            return reversedHoroscopes.length - 1;
        }

        return reversedHoroscopes.length - 1;
    };

    const [activeHoroscopeIndex, setActiveHoroscopeIndex] = useState(getInitialActiveHoroscopeIndex());

    const getActiveHoroscope = () => {
        if (activeHoroscopeIndex === allHoroscopes.length - 1) {
            const newActiveHoroscope = allHoroscopes[activeHoroscopeIndex].horoscopeData;
            newActiveHoroscope.free_trial_days_left = allHoroscopes[activeHoroscopeIndex].free_trial_gold_days_left;
            newActiveHoroscope.has_one_off_purchase = allHoroscopes[activeHoroscopeIndex].has_one_off_purchase;
            newActiveHoroscope.is_user_subscribed = allHoroscopes[activeHoroscopeIndex].is_user_subscribed;
            newActiveHoroscope.current_period = allHoroscopes[activeHoroscopeIndex].current_period;
            return newActiveHoroscope;
        }
        return allHoroscopes[activeHoroscopeIndex];
    };

    const getCarouselTitle = (horoscopeData) => {
        if (horoscopeData?.current_period?.cycle === 2) {
            return `${moment(horoscopeData?.current_period?.from).format('MMMM YYYY')}`;
        }
        return `${moment(horoscopeData?.current_period?.from).format('MMMM DD')} - ${moment(horoscopeData?.current_period?.to).format('MMMM DD')}`;
    };

    const activeHoroscope = getActiveHoroscope();

    const showBuyButton = singleHoroscope?.horoscopeData?.id === activeHoroscope?.id;

    const horoscopeTranslation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);
    const horoscopeData = getSelectedTranslation(activeHoroscope?.translations, language?.selectedLanguage?.id);

    const videoCode = activeHoroscope?.free_trial_days_left === 0 ? horoscopeData?.video_link_preview : horoscopeData?.video_link;

    useEffect(() => {
        // eslint-disable-next-line
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // add or remove refs
        setElRefs((currentRef) => Array(partsLength)
            .fill()
            .map((_, i) => currentRef[i] || createRef()));
    }, [partsLength]);

    const getBackLink = () => {
        if (singleHoroscope?.current_period.cycle === 2) {
            return '/dashboard?tab=2';
        }
        if (singleHoroscope?.current_period.cycle === 3) {
            return '/dashboard?tab=3';
        }
        return '/dashboard?tab=1';
    };

    const carouselData = reversedHoroscopes.map((item, index) => ({
        id: index + 1,
        onClick: () => setActiveHoroscopeIndex(index),
        title: getCarouselTitle(item),
    }));

    const renderHoroscopePart = (partData, partDataIndex) => {
        const horoscopePartLanguage = getSelectedTranslation(partData?.translations, language?.selectedLanguage?.id);
        return (
            <div
                className="chapter-block"
                key={`single-part-${partData.id}`}
                ref={elRefs[partDataIndex]}>
                <h3>{horoscopePartLanguage?.name}</h3>
                {horoscopePartLanguage?.video_link
                    && (
                        <div className="video-block">
                            <iframe src={`https://www.youtube.com/embed/${horoscopePartLanguage?.video_link}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </div>
                    )}
                <div>{horoscopePartLanguage?.description}</div>
            </div>
        );
    };

    const scrollTo = (dataScrollTo) => {
        elRefs[dataScrollTo].current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Main
            className="align-center"
            title={<div>Welcome</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menuWidth={350}
            menuPlacement="right"
            displayRightMenu
            floatingHeader
            showFooter={false}
            scrollTo={scrollTo}
            backLink={(
                <div style={{ display: 'flex' }}>
                    <Link to={getBackLink} className="backlink" style={{ marginBottom: '24px' }} />
                </div>
            )}>
            <div className="content single-horoscope-content">
                {activeHoroscope?.current_period.cycle !== 3 && (
                    <div className="carousel-wrapper">
                        <HoroscopeCarousel data={carouselData} activeSlide={activeHoroscopeIndex + 1} />
                    </div>
                )}
                <h1 className="page-title">{horoscopeTranslation?.name}</h1>
                <h4 className="page-subtitle">{getHoroscopeDataRange(singleHoroscope)}</h4>
                {activeHoroscope?.is_user_subscribed === false && activeHoroscope?.has_one_off_purchase === false && showBuyButton === true
                    ? (
                        <div className="one-off-buy-btn-wrapper">
                            <OneOffPurchaseButton btnClass="" horoscopeContentFrequency={singleHoroscope?.current_period?.cycle} />
                        </div>
                    )
                    : null }
                {!isEmpty(videoCode) ? (
                    <div className="video-block">
                        <iframe src={`https://www.youtube.com/embed/${videoCode}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </div>
                ) : null }

                {(activeHoroscope?.free_trial_days_left === 0 || isUndefined(activeHoroscope?.free_trial_days_left)) && activeHoroscope?.is_user_subscribed === false && activeHoroscope?.has_one_off_purchase === false || activeHoroscope?.current_period.cycle === 3
                    ? (
                        <div>

                            <div className="horoscope-description-block" dangerouslySetInnerHTML={{ __html: sanitizer(horoscopeData?.description_preview) }} />
                        </div>
                    )
                    : null }

                {((activeHoroscope?.free_trial_days_left > 0 || activeHoroscope?.is_user_subscribed === true || activeHoroscope?.has_one_off_purchase === true) && activeHoroscope?.current_period.cycle !== 3) ? (
                    <div>
                        <div className="horoscope-description-block">{horoscopeData?.description}</div>
                        {activeHoroscope?.parts?.map((partItem, partIndex) => (
                            renderHoroscopePart(partItem, partIndex)
                        ))}
                    </div>
                )
                    : null }
            </div>
        </Main>
    );
};

export default SingleHoroscope;

SingleHoroscope.propTypes = {};

/* eslint-disable react/no-danger */

import React, { useEffect, useState } from 'react';
import { Button, Image, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { isEmpty, isNull } from 'underscore';
import useHoroscope from '../../core/horoscope/useHoroscope';
import Main from '../../components/layout/Main';
import './horoscope.scss';
import history from '../../core/utils/history';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import { findUserHoroscopeRequest, userActiveHoroscopesRequest, selectSingleHoroscopeRequest } from '../../core/horoscope/horoscopeActions';

const HoroscopeGenerate = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const { language } = useLanguage();
    const sanitizer = DOMPurify.sanitize;

    const { horoscope, singleHoroscope } = useHoroscope();

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [redirectToHoroscopeDetail, setRedirectToHoroscopeDetail] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
        setRedirectToHoroscopeDetail(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // const moonsignSelect = () => {
    //     history.push('/payment-plan-select');
    // };

    useEffect(() => {
        showModal();
        let findUserHoroscope = true;

        if (isNull(horoscope?.userActiveHoroscopes) || isEmpty(horoscope?.userActiveHoroscopes)) {

            dispatch(userActiveHoroscopesRequest({ refresh: true }));
        }

        if (location?.state?.hideModal === true) {
            findUserHoroscope = false;
        }

        if (findUserHoroscope === true) {
            dispatch(findUserHoroscopeRequest());
        }
    },
    []);

    useEffect(() => {
        if (location?.state?.hideModal === true) {
            setIsModalVisible(false);
            setRedirectToHoroscopeDetail(false);
        }
    }, [location]);

    const translation = getSelectedTranslation(horoscope?.singleHoroscope?.translations, language?.selectedLanguage?.id);

    const handleButtonClick = () => {
        const weeklyDataArr = horoscope?.userActiveHoroscopes?.Weekly;
        const horoscopeData = weeklyDataArr.find((obj) => obj?.horoscope.id === horoscope?.singleHoroscope?.id);
        dispatch(selectSingleHoroscopeRequest(horoscopeData?.horoscope));
        if (redirectToHoroscopeDetail) {
            setRedirectToHoroscopeDetail(false);
            history.push('/horoscope-details');
        } else {
            history.push('/payment-plan-select');
        }
    };

    return (
        <Main
            className="horoscopeGenerated center-align"
            title={<div>Welcome</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showHeader
            showFooter={false}
            backLink={(<div style={{ display: 'flex' }}><div onClick={() => history.goBack()} className="backlink" style={{ marginBottom: '24px', cursor: 'pointer' }} /></div>)}>
            <div>
                <h1 className="title-text">{getTranslation(language, 'moonsign_generate_text_title', 'We have generated your Moonsign')}  </h1>
                <Image preview={false} height={354} width={337} src={`${singleHoroscope?.mediaUrl}`} />
                <p className="sub-title-text" style={{ fontFamily: 'Inter' }}> {translation?.name} </p>
                <div>
                    {/* <p className="description">
                        {singleHoroscope?.monthFromString} {singleHoroscope?.day_from}
                        - {singleHoroscope?.monthToString} {singleHoroscope?.date_to}
                    </p> */}
                    <p className="description" style={{ fontFamily: 'Inter' }} dangerouslySetInnerHTML={{ __html: sanitizer(translation?.short_description) }} />
                </div>

                {horoscope.isFetching ? (
                    <Button type="primary" style={{ marginBottom: 10 }} className="ant-btn-yellow" loading>
                        {getTranslation(language, 'navigation_continue_button_title', 'Continue')}
                    </Button>
                ) : (
                    <Button type="primary" htmlType="submit" onClick={() => handleButtonClick()} style={{ marginBottom: 10 }} className="ant-btn-yellow">
                        {getTranslation(language, 'navigation_continue_button_title', 'Continue')}

                    </Button>
                )}
                <div>

                    <Link to="/moonsign-select" style={{ marginTop: 20 }}>  {getTranslation(language, 'moonsign_generate_choose_manual_option_title', 'Choose manually')}
                    </Link>
                </div>

            </div>
            <Modal centered
                className="reveal-moonsign-modal"
                visible={isModalVisible}
                bodyStyle={{ padding: '0px' }}
                closable={false}
                footer={null}
                maskStyle={{ backgroundColor: 'rgba(52, 52, 52, 0.97)' }}>
                <div className="reveal-moonsign-modal-content">
                    <p className="reveal-modal-title">{getTranslation(language, 'reveal_moonsign_title', 'Reveal your Moonsign')}</p>
                    {horoscope.isFetching ? (
                        <Button type="primary" className="ant-btn-yellow reveal-moonsign-modal-button" loading>
                            {getTranslation(language, 'navigation_yes_button_title', 'Yes')}
                        </Button>
                    ) : (
                        <Button type="primary" className="ant-btn-yellow reveal-moonsign-modal-button" onClick={handleCancel}>
                            {getTranslation(language, 'navigation_yes_button_title', 'Yes')}
                        </Button>
                    )}
                </div>
            </Modal>
        </Main>
    );
};

export default HoroscopeGenerate;

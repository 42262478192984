import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { getTranslation, getSelectedTranslation, getHoroscopeDataRange, getHoroscopeFullDataRange } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';
import CountDownTimer from '../../CountDownTimer';
import defaultLogo from '../../../assets/images/logo.png';
import './newRightMenu.scss';
import YellowThemeButton from '../../YellowThemeButton';
import YellowButtonInverted from '../../YellowButtonInverted';
import { selectSingleHoroscopeRequest, selectSingleLifeEventRequest } from '../../../core/horoscope/horoscopeActions';
import GeneratedMoonsign from '../../GeneratedMoonsign';

const NewRightMenu = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();

    const horoscope = useSelector(state => state.horoscope);
    const user = useSelector(state => state.user);

    const { language } = useLanguage();
    const { singleHoroscope } = horoscope;
    const { userData } = user;

    const isUserSubscribed = singleHoroscope?.is_user_subscribed;

    const selectedHoroscope = horoscope?.userActiveHoroscopes?.Yearly?.find((horoscopeObj) => horoscopeObj?.horoscope?.id === singleHoroscope?.id);
    const horoscopeParts = selectedHoroscope?.horoscope?.horoscopeData?.parts;
    const filteredParts = horoscopeParts?.filter((part) => part.user_has_access);

    let timeLeft = singleHoroscope?.free_trial_days_left ? singleHoroscope?.free_trial_days_left : 0;
    let goldTimeLeft = singleHoroscope?.free_trial_gold_days_left ? singleHoroscope?.free_trial_gold_days_left : 0;
    const freeTrialCoupon = singleHoroscope?.free_trial_coupon_days_left ? singleHoroscope?.free_trial_coupon_days_left : 0;

    if (timeLeft > 0) {
        const unixCreateTime = moment(userData?.created_at).add(91, 'day').unix();
        const unixTodaysDate = moment().unix();

        const unixTimeLeft = unixCreateTime - unixTodaysDate;

        if (unixTimeLeft > 0) {
            timeLeft = parseInt(unixTimeLeft, 10);
        } else {
            timeLeft = 0;
        }

        if (singleHoroscope?.is_user_subscribed === true) {
            timeLeft = 0;
        }
    }

    if (goldTimeLeft > 0) {
        const unixCreateTime = moment(userData?.created_at).add(91, 'day').unix();
        const unixTodaysDate = moment().unix();

        const unixTimeLeft = unixCreateTime - unixTodaysDate;

        if (unixTimeLeft > 0) {
            goldTimeLeft = parseInt(unixTimeLeft, 10);
        } else {
            goldTimeLeft = 0;
        }

        if (singleHoroscope?.is_user_subscribed === true) {
            goldTimeLeft = 0;
        }
    }

    const horoscopeTranslation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);

    const location = useLocation();
    const history = useHistory();

    const openDrawer = () => {
        setMenuOpen(true);
    };

    const closeDrawer = () => {
        setMenuOpen(false);
    };

    const handleHoroscopePartClick = (part) => {
        dispatch(selectSingleLifeEventRequest(part));
        closeDrawer();
        history.push('/life-event-view');
    };

    const handleSignUpClick = () => {
        history.push({ pathname: '/moonsign-select', state: { hideModal: true } });
    };

    const handleViewMonthlyHoroscopeClick = () => {
        const monthlyHoroscope = horoscope?.userActiveHoroscopes?.Monthly?.find((horoscopeObj) => horoscopeObj?.horoscope?.id === singleHoroscope?.id);
        dispatch(selectSingleHoroscopeRequest(monthlyHoroscope.horoscope));
        history.push('/horoscope-details');
    };

    const handleViewWeeklyHoroscopeClick = () => {
        const weeklyHoroscope = horoscope?.userActiveHoroscopes?.Weekly?.find((horoscopeObj) => horoscopeObj?.horoscope?.id === singleHoroscope?.id);
        dispatch(selectSingleHoroscopeRequest(weeklyHoroscope.horoscope));
        history.push('/horoscope-details');
    };

    const renderHoroscopePart = (partData) => {
        const horoscopePartLanguage = getSelectedTranslation(partData?.translations, language?.selectedLanguage?.id);

        return (
            <li key={`side-menu-part-item-${partData.id}`}>
                <button type="button" onClick={() => handleHoroscopePartClick(partData)}>
                    {horoscopePartLanguage?.name}
                </button>
            </li>
        );
    };

    const renderTimer = () => {
        if (goldTimeLeft > 0 && timeLeft === 0) {
            return (
                <CountDownTimer timerLeft={goldTimeLeft} />
            );
        }

        if (freeTrialCoupon > 0) {
            return (
                <CountDownTimer timerLeft={freeTrialCoupon} />
            );
        }

        return (
            <CountDownTimer timerLeft={timeLeft} />
        );

    };

    return (
        <div className="menu">
            <Button className="right-menu-toggle-button" onClick={openDrawer}>
                <div className="right-menu-toggle-icon" />
            </Button>
            <Drawer
                visible={menuOpen}
                onClose={closeDrawer}
                placement="right"
                width="350"
                className="right-menu-drawer"
                closable={false}>
                <div className="menu__header" style={{ marginTop: menuOpen ? '20px' : null }}>
                    <Button className="right-menu-toggle-button" onClick={closeDrawer}>
                        <div className="right-menu-toggle-icon" />
                    </Button>
                </div>
                <div className="moonsign-block">
                    <GeneratedMoonsign />
                </div>
                <div className="right-menu-logo-block">
                    {renderTimer()}
                    <img src={singleHoroscope?.mediaUrl} alt="horoscope" />
                    <h4 className="logo-title">{horoscopeTranslation?.name} {getTranslation(language, 'yearly_horoscope', 'Yearly Horoscope')} {getHoroscopeDataRange(singleHoroscope)}</h4>
                    <p>{getHoroscopeFullDataRange(singleHoroscope)}</p>
                </div>
                <div className="new-right-menu-life-events-block">
                    <h4>{getTranslation(language, 'your_life_events_title', 'Your Life Events')}</h4>
                    <ul className="life-events-list">
                        {filteredParts.map((partItem, index) => (
                            renderHoroscopePart(partItem, index)
                        ))}
                    </ul>
                </div>

                <div className="menu__footer">
                    <div className="top-button-wrapper">
                        {isUserSubscribed
                            ? (
                                <div>
                                    <YellowThemeButton onClick={handleViewWeeklyHoroscopeClick} style={{ marginBottom: '16px', fontSize: '14px' }}>{getTranslation(language, 'view_weekly_horoscopes', 'View Weekly Horoscope')}</YellowThemeButton>
                                    <YellowThemeButton onClick={handleViewMonthlyHoroscopeClick} style={{ fontSize: '14px' }}>{getTranslation(language, 'view_monthly_horoscopes', 'View Monthly Horoscope')}</YellowThemeButton>
                                </div>
                            )
                            : (
                                <>
                                    {location.pathname !== '/life-events' && (
                                        <Link to="/life-events">
                                            <YellowThemeButton style={{ marginBottom: '16px', fontSize: '14px' }}>{getTranslation(language, 'purchase_life_events', 'Purchase Life Events')}</YellowThemeButton>
                                        </Link>
                                    )}
                                    <YellowThemeButton onClick={handleSignUpClick} style={{ marginBottom: '16px', fontSize: '14px' }}>{getTranslation(language, 'sign_up_to_diamond_package', 'Sign Up to The Diamond Package')}</YellowThemeButton>
                                    <YellowThemeButton onClick={handleViewWeeklyHoroscopeClick} style={{ marginBottom: '16px', fontSize: '14px' }}>{getTranslation(language, 'view_weekly_horoscopes', 'View Weekly Horoscope')}</YellowThemeButton>
                                    <YellowThemeButton onClick={handleViewMonthlyHoroscopeClick} style={{ fontSize: '14px' }}>{getTranslation(language, 'view_monthly_horoscopes', 'View Monthly Horoscope')}</YellowThemeButton>
                                </>
                            )}
                    </div>
                    <div className="bottom-button-wrapper">
                        <Link to="my-subscriptions">
                            <YellowButtonInverted style={{ marginBottom: '16px', fontSize: '14px' }}>{getTranslation(language, 'view_subscriptions', 'View Subscriptions')}</YellowButtonInverted>
                        </Link>
                        <a href="https://www.prakashastrologer.com/disclaimer/" target="_blank" rel="noopener noreferrer">
                            <YellowButtonInverted style={{ fontSize: '14px' }}>{getTranslation(language, 'astrology_disclaimer', 'Astologer Disclaimer')}</YellowButtonInverted>
                        </a>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

NewRightMenu.propTypes = {
    items: PropTypes.array,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placement: PropTypes.string,
    user: PropTypes.object,
    width: PropTypes.number,
};

NewRightMenu.defaultProps = {
    items: [],
    logo: defaultLogo,
    placement: 'right',
    width: 256,
    user: {},
};

export default NewRightMenu;

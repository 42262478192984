import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import lockr from 'lockr';
import { isEmpty, isNull } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification } from 'antd';
import Main from '../../components/layout/Main';
import './enterLifeEventPaymentMobile.scss';
import { getUserDetailsRequest } from '../../core/user/userActions';
import { buyLifeEventRequest, getPaymentCardsRequest } from '../../core/payment/paymentActions';
import { getTranslation } from '../../core/utils/helpers';
import useLanguage from '../../core/language/useLanguage';
// import AddPaymentCardFormMobile from './AddPaymentCardFormMobile/index';
import { userActiveHoroscopesRequest } from '../../core/horoscope/horoscopeActions';
import AddPaymentCardFormMobile from './AddPaymentCardFormMobile/index';
import YellowThemeButton from '../../components/YellowThemeButton';
import plusIcon from '../../assets/images/plus-icon.svg';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../../core/constants').default;

const EnterLifeEventPaymentMobile = () => {
    // Get params from URL
    const params = useParams();
    const dispatch = useDispatch();
    const { language } = useLanguage();
    const { authToken, lifeEventIds } = params;

    const lifeEvents = lifeEventIds.split(',').map(Number);
    const { paymentCards, isFetching: isFetchingPaymentCards } = useSelector(state => state.payment);

    const [addPaymentCard, setAddPaymentCard] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);

    useEffect(async () => {
        // Save Auth Token and date
        if (authToken && !isNull(authToken)) {
            const authDate = new Date();
            lockr.set(AUTH_KEY, authToken);
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
        }

        dispatch(getUserDetailsRequest());
        dispatch(userActiveHoroscopesRequest());
        dispatch(getPaymentCardsRequest());

        if (isEmpty(paymentCards)) {
            console.log('no payment card found');
            // setAddPaymentCard(true);
        }
    }, []);

    const renderPaymentCard = (paymentCard) => {
        let active = false;

        if (paymentCard.stripe_card_id === selectedCard) {
            active = true;
        }

        return (
            <div className={`payment-card-block ${active === true ? 'active' : ''}`} key={`payment-card-${paymentCard.stripe_card_id}`} onClick={() => setSelectedCard(paymentCard.stripe_card_id)}>
                <p className="card-number">**** **** **** {paymentCard.last_4}, {paymentCard.brand}</p>
                <p className="brand">{paymentCard.name}, {paymentCard.exp_month}/{paymentCard.exp_year}</p>
            </div>
        );
    };

    const makePayment = () => {

        let error = false;

        if (selectedCard === null) {
            error = true;
            notification.info({ message: 'Info', description: 'Please select payment card' });
        }

        if (!error) {
            dispatch(buyLifeEventRequest({
                horoscopeDataParts: lifeEvents,
                selectedLang: language?.selectedLanguage?.id || 1,
                card_id: selectedCard,
                payment_type: 'web',
            }));
        }

    };

    return (
        <Main
            className="center-align"
            title={<div>Welcome</div>}
            headerTitle={<div>Horoscope Generated</div>}
            menuWidth={350}
            menuPlacement="right"
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showHeader={false}
            showFooter={false}>
            <div>
                <h1 className="title-text">{getTranslation(language, 'webapp_enter_card_details_title', 'Enter Card Details')}</h1>
                <div type="flex">
                    {addPaymentCard ? (
                        <div>
                            <AddPaymentCardFormMobile type="lifeEvent" lifeEvents={lifeEvents} />
                            <Button className="text-small ant-btn-link" onClick={() => setAddPaymentCard(false)}>  {getTranslation(language, 'webapp_back_link_title', 'Back')} </Button>
                        </div>
                    ) : (
                        <div>
                            <YellowThemeButton style={{ width: '380px', maxWidth: '100%', marginBottom: '20px' }} onClick={() => setAddPaymentCard(true)}>
                                <div className="button-content-container">
                                    <img className="add-plus-icon" src={plusIcon} alt="" />
                                    {getTranslation(language, 'payment_card_add_new_card_button_label', 'Add New Card')}
                                </div>
                            </YellowThemeButton>
                            <div className="user-payment-cards">
                                {
                                    paymentCards.map(paymentCard => (
                                        renderPaymentCard(paymentCard)
                                    ))
                                }
                            </div>
                            {isFetchingPaymentCards ? (
                                <Button type="primary" className="ant-btn-yellow" loading>
                                    {getTranslation(language, 'payment_card_form_pay_button_label', 'Pay')}
                                </Button>
                            ) : (
                                <Button type="primary" className="ant-btn-yellow" onClick={() => makePayment()}>

                                    {getTranslation(language, 'payment_card_form_pay_button_label', 'Pay')}
                                </Button>
                            )}
                            <br />
                        </div>
                    )}

                </div>
            </div>
        </Main>
    );
};

EnterLifeEventPaymentMobile.propTypes = {

};

EnterLifeEventPaymentMobile.defaultProps = {

};

export default EnterLifeEventPaymentMobile;

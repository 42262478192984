/* eslint-disable no-param-reassign */

import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message, Input } from 'antd';
import { isUndefined, isNull } from 'underscore';
import Main from '../../components/layout/Main';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation, getSelectedTranslation, convertToTranslationIndex } from '../../core/utils/helpers';
import history from '../../core/utils/history';
import './consultationForm.scss';

const ConsultationForm = () => {
    const user = useSelector(state => state.user.userData);
    const { language } = useLanguage();
    const consultation = useSelector(state => state.consultation);
    const { singleConsultation } = consultation;
    const { questions } = singleConsultation;
    const [checkboxQuestions, setCheckboxQuestions] = useState([]);
    const [numChecked, setNumChecked] = useState(0);
    const [otherQuestions, setOtherQuestions] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const didMount = useRef(false);
    // const params = useParams();

    const location = useLocation();
    const { prevPath } = location.state;

    useEffect(() => {
        const questionsCopy = [...questions];
        questionsCopy.forEach((question) => {
            question.isChecked = false;
            question.isDisabled = false;
        });
        setCheckboxQuestions(questionsCopy);
    }, []);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        setCheckboxQuestions(checkboxQuestions?.map((q) => {
            if (numChecked === 3 && !q.isChecked) {
                q.isDisabled = true;
            } else {
                q.isDisabled = false;
            }
            return q;
        }));
    }, [numChecked]);

    const checkChanged = (e, id) => {
        setCheckboxQuestions(checkboxQuestions.map((q) => {
            if (e.target.checked) {
                setNumChecked(numChecked + 1);
            } else {
                setNumChecked(numChecked - 1);
            }
            if (q.id === id) {
                q.isChecked = e.target.checked;
            }
            return q;
        }));
    };

    const handleSubmit = () => {
        if (phoneNumber && phoneNumber !== '') {
            // eslint-disable-next-line no-useless-escape
            const pattern = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
            if (!pattern.test(phoneNumber)) {
                message.warning(getTranslation(language, 'phone_number_error', 'Invalid phone number'));
                return;
            }
        }

        if (numChecked === 0 && otherQuestions === '') {
            message.warning(getTranslation(language, 'empty_form_field_error', 'Select at least one question or fill in the other question field'));
        } else {
            const data = {
                consultation_id: singleConsultation.id,
                user_id: user?.id,
                questions: checkboxQuestions.filter((q) => q.isChecked),
                extra_questions: otherQuestions,
                phone_number: phoneNumber,
            };

            // Need to implement the addConsultationToBasketRequest action with Redux
            if (!isUndefined(data)) {
                history.push({ pathname: '/consultation-confirmation', state: { data } });
            }

        }
    };

    const getUrl = () => {

        if (isUndefined(prevPath) || isNull(prevPath)) {
            return '/consultations';

        }
        return `/consultation-detail/${prevPath}`;

    };

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            backLink={(
                <Link to={getUrl} className="backlink" />
            )}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            displayRightMenu={false}
            showFooter={false}>
            <div className="consultation-form">
                <h2 className="cform-heading">{getTranslation(language, convertToTranslationIndex(singleConsultation?.name), singleConsultation?.name)}</h2>
                <div className="cform-content">
                    <h3 className="cform-sub-heading">{getTranslation(language, 'consultations_form_questions_title', 'Questions')}</h3>
                    <span className="cform-sub-strap">{getTranslation(language, 'consultations_form_max_questions', 'Please select up to 3 questions')}</span>
                    <div className="cform-questions">
                        {checkboxQuestions.filter((question) => question.status === 'Active').map((data) => {
                            const questionTranslation = getSelectedTranslation(data?.translations, language?.selectedLanguage?.id);
                            if (!questionTranslation) return null;
                            return (
                                <div key={`optionItem-${data.id}`} className="cform-question">
                                    <input id={data.id}
                                        type="checkbox"
                                        className="cform-checkbox"
                                        name="questions"
                                        checked={data.isChecked}
                                        disabled={data.isDisabled}
                                        onChange={(e) => checkChanged(e, data.id)} />
                                    <label htmlFor={data.id}>{questionTranslation?.name}</label>
                                </div>
                            );
                        })}
                    </div>
                    <div className="cform-other-questions">
                        <h3 className="cform-sub-heading">{getTranslation(language, 'consultations_screen_other_questions_title', 'Other Questions')}</h3>
                        <span className="cform-sub-strap">{getTranslation(language, 'consultations_screen_other_questions_optional', 'Optional')}</span>
                        <div className="cform-other-questions-container">
                            <textarea className="cform-other-questions-input"
                                name="other-questions"
                                placeholder={getTranslation(language, 'consultations_screen_other_questions_placeholders', 'Please enter your question')}
                                onChange={(e) => setOtherQuestions(e.target.value)} />
                        </div>
                    </div>
                    <div className="cform-phone-number">
                        <h3 className="cform-sub-heading">{getTranslation(language, 'phone_number', 'Phone Number')}</h3>
                        <span className="cform-sub-strap">{getTranslation(language, 'phone_number_desc', 'Please enter your telephone number')}</span>
                        <div className="cform-phone-number-container">
                            <Input
                                type="number"
                                name="phone_number"
                                value={phoneNumber.substring(0, 16)}
                                maxLength={16}
                                placeholder={getTranslation(language, 'phone_number_desc', 'Please enter your telephone number')}
                                onChange={(e) => setPhoneNumber(e.target.value)} />
                        </div>
                    </div>
                    <button
                        type="button"
                        className="cform-continue"
                        onClick={handleSubmit}>
                        {getTranslation(language, 'consultation_screen_submit_button_title', 'Continue')}
                    </button>
                </div>
            </div>
        </Main>
    );
};

export default ConsultationForm;
